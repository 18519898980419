import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {ListModule} from '../../../craft/list/list.module'
import {AccordionModule} from '../../../craft/accordion/accordion.module'
import {CarouselAccordionComponent} from '../../../craft/carousel-accordion-section/carousel-accordion.component'
import {BehaviorSubject} from 'rxjs'
import {PresentationSectionItem} from '../../../core/content/types/presentation-section.types'
import {RichTextModule} from '../rich-text/rich-text.module'
import {ButtonModule} from '../../../craft/button/button.module'
import {YoutubeVideoModule} from '../../../craft/youtube-video/youtube-video.module'
import {NgOptimizedImage} from '@angular/common'
import {DatoCmsGifPipe} from '../../pipes/datocms.pipe'

@Component({
  selector: 'bgo-presentation-section',
  templateUrl: './presentation-section.component.html',
  styles: [':host {@apply block;}'],
  imports: [
    ListModule,
    AccordionModule,
    CarouselAccordionComponent,
    YoutubeVideoModule,
    RichTextModule,
    ButtonModule,
    NgOptimizedImage,
    DatoCmsGifPipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresentationSectionComponent {
  @Input() plot!: string
  @Input() title!: string
  @Input() items: PresentationSectionItem[] = []
  protected readonly index$ = new BehaviorSubject(0)

  changeIndex(index: number) {
    this.index$.next(index)
  }

  getLetter(index: number): string {
    return `${index + 1}`
  }
}
